/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FormEvent, FunctionComponent, useEffect, useState,
} from 'react';
import {
    Button,
    Chip,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
    endsWith, findIndex, isEqual, omit,
} from 'lodash';
import update from 'immutability-helper';

import { useSnackbar } from 'notistack';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { withWorksContext, WorksContext } from '../controllers/works/WorksContext';
import WorkFormIdentifiers from '../elements/work/WorkFormIdentifiersTable';
import {
    Work,
    WorkAgent,
    WorkCountry,
    WorkDate,
    WorkFormFields,
    WorkPayload,
    WorkThirdPartyIDs,
    WorkTitle,
    WorkTitleType,
} from '../../types/works';
import WorkFormTitlesTable from '../elements/work/WorkFormTitlesTable';
import {
    ControlledVocabulariesContext,
    withControlledVocabulariesContext,
} from '../controllers/controlled_vocabularies/ControlledVocabulariesContext';
import { SoundProductionCV } from '../../types/controlled_vocabularies';
import { ControlledVocabularyName } from '../../services/controlled_vocabularies';
import WorkFormDatesTable from '../elements/work/WorkFormDatesTable';
import ChipsInput from '../elements/ChipsInput';
import WorkFormAgentsTable from '../elements/work/WorkFormAgentsTable';
import Loader from '../elements/Loader';
import { buildRoute, getRandomInt } from '../../utils/misc';
import { AppRoute } from '../../constants/routes';
import IconCircleWork from '../assets/IconCircleWork';
import { ArchiveWork } from '../../types/archive_works';
import WorkFormItems from '../elements/work/WorkFormItems';
import { ItemsContext, withItemsContext } from '../controllers/items/ItemsContext';
import { ApiError } from '../../types/errors';
import WorkFormCountriesTable from '../elements/work/WorkFormCountriesTable';
import AgentFormDialog from '../elements/agent/AgentFormDialog';
import { Agent } from '../../types/agents';
import { MIN_WORK_YEAR } from '../../constants/misc';

interface OwnProps extends TranslationContext, WorksContext, ItemsContext, ControlledVocabulariesContext {}

enum FieldName {
    PreferredTitle = 'preferred_title',
    Notes = 'notes',
    SoundProduction = 'sound_production',
    Description = 'description',
    Countries = 'countries',
}

const initialFieldsValues: WorkFormFields = {
    preferred_title: '',
    sound_production: '',
    notes: '',
    description: '',
    series: [],
    thirdPartyWorkIds: [],
    titles: [],
    countries: [],
    dates: [],
    agents: [],
};

const WorkFormScreen: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, getCV, createWork, getWork, updateWork,
    } = props;

    const navigate = useNavigate();
    const params = useParams();

    const { enqueueSnackbar } = useSnackbar();

    const [isFetching, setIsFetching] = useState(false);
    const [fields, setFields] = useState<WorkFormFields>(initialFieldsValues);
    const [soundProductionOptions, setSoundProductionOptions] = useState<Array<SoundProductionCV>>([]);
    const [work, setWork] = useState<Work | null>(null);
    const [workArchives, setWorkArchives] = useState<Array<ArchiveWork>>([]);
    const [agentSelectedToEdit, setAgentSelectedToEdit] = useState<number | null>(null);

    useEffect(() => {
        const requestOptions = async () => {
            const results = await getCV<SoundProductionCV>(ControlledVocabularyName.SoundProductions);
            if (results) {
                setSoundProductionOptions(results.data);
            }
        };

        requestOptions();
    }, []);

    useEffect(() => {
        prepare();
    }, [params]);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        const isYearValidated = fields.dates.every((date) => onCheckYears(date));
        if (isYearValidated) {
            setIsFetching(true);
            const titles = [...fields.titles];
            const preferredTitleId = work?.titles.find((singleTitle) => singleTitle.title_type === WorkTitleType.Preferred)?.id || -1;
            titles.push({
                id: Number(preferredTitleId),
                title_type: WorkTitleType.Preferred,
                value: fields[FieldName.PreferredTitle].trim(),
            });
    
            const workPayload: WorkPayload = {
                content_description: fields[FieldName.Description].trim(),
                sound_production: fields[FieldName.SoundProduction].trim(),
                notes: fields[FieldName.Notes].trim(),
                titles,
                series: fields.series,
                countries: fields.countries,
                dates: fields.dates,
                agents: fields.agents,
                thirdPartyWorkIds: fields.thirdPartyWorkIds,
            };
    
            if (work) {
                updateWork(
                    work.fiaf_work_id,
                    workPayload,
                    onEditSuccess,
                    onRequestFailure,
                );
                return;
            }
    
            createWork(workPayload, onCreateSuccess, onRequestFailure);
        }
    };

    const onCreateSuccess = (workCreated: Work) => {
        enqueueSnackbar(t('work.form.successfullyCreated'), { variant: 'success' });
        navigate(buildRoute(AppRoute.WorkDetails, { id: workCreated.fiaf_work_id }));
    };

    const onRequestFailure = (error: ApiError) => {
        setIsFetching(false);
        if (error) {
            if (error.errors && Object.keys(error.errors).some((key) => endsWith(key, '0.value'))) {
                return enqueueSnackbar(t('general.fillRequiredFields'), { variant: 'error' });
            }
            return enqueueSnackbar(error.message, { variant: 'error' });
        }
        enqueueSnackbar(t('general.requestGeneralError'), { variant: 'error' });
    };

    const onEditSuccess = () => {
        enqueueSnackbar(t('work.form.successfullyEdited'), { variant: 'success' });
        if (work) navigate(buildRoute(AppRoute.WorkDetails, { id: work.fiaf_work_id }));
    };

    const onAddNewThirdPartyName = (newThirdPartyName: WorkThirdPartyIDs) => {
        let thirdPartyName = { ...newThirdPartyName };
        const existentPartyName = work?.thirdPartyWorkIds.find((el) => el.id_value === newThirdPartyName.id_value && el.third_party_name === newThirdPartyName.third_party_name);
        if (existentPartyName) {
            thirdPartyName = existentPartyName;
        }
        setFields({
            ...fields,
            thirdPartyWorkIds: [...fields.thirdPartyWorkIds, thirdPartyName],
        });
    };

    const onThirdPartyNameEdited = (thirdPartyName: WorkThirdPartyIDs) => {
        const idx = fields.thirdPartyWorkIds.findIndex((n) => n.id === thirdPartyName.id);
        setFields({
            ...fields,
            thirdPartyWorkIds: update(fields.thirdPartyWorkIds, { [idx]: { $set: thirdPartyName } }),
        });
    };

    const onRemoveIdentifier = (id: number) => {
        setFields({
            ...fields,
            thirdPartyWorkIds: [...fields.thirdPartyWorkIds.filter((thirdPartyId) => thirdPartyId.id !== id)],
        });
    };

    const onAddNewTitle = (newTitle: WorkTitle) => {
        let workTitle = { ...newTitle };
        const existentTitle = work?.titles.find((title) => title.value === newTitle.value && title.title_type === newTitle.title_type);
        if (existentTitle) workTitle = existentTitle;
        setFields((fieldsValue) => ({
            ...fieldsValue,
            titles: [...fieldsValue.titles, workTitle],
        }));
    };

    const onRemoveTitle = (id: number) => {
        setFields((fieldsValue) => ({
            ...fieldsValue,
            titles: [...fieldsValue.titles.filter((title) => title.id !== id)],
        }));
    };

    const onAddNewCountry = (newCountry: WorkCountry) => {
        let workCountry = { ...newCountry };
        const existentCountry = work?.countries?.find((c) => c.country_ISO_code === newCountry.country_ISO_code) || undefined;
        if (existentCountry) workCountry = { ...existentCountry, country_probable: newCountry.country_probable };
        setFields({
            ...fields,
            countries: [...fields.countries, workCountry],
        });
    };

    const onRemoveCountry = (countryIsoCode: string) => {
        setFields({
            ...fields,
            countries: [...fields.countries.filter((c) => c.country_ISO_code !== countryIsoCode)],
        });
    };

    const onAddNewSerie = (value: string) => {
        let serie = { id: getRandomInt(-10000, 0), title: value };
        const existentSerie = work?.series.find((s) => s.title === value);
        if (existentSerie) serie = existentSerie;

        setFields((fieldsValues) => ({
            ...fieldsValues,
            series: [...fieldsValues.series, serie],
        }));
    };

    const onRemoveSerie = (value: string) => {
        setFields((fieldsValues) => ({
            ...fieldsValues,
            series: [...fieldsValues.series.filter((serie) => serie.title !== value)],
        }));
    };

    const onAddNewDate = (newDate: WorkDate) => {
        let workDate = { ...newDate };
        const existentDate = work?.dates ? work.dates.find((d) => isEqual(omit(d, ['id']), omit(workDate, ['id']))) : null;
        if (existentDate) workDate = existentDate;
        setFields({
            ...fields,
            dates: [...fields.dates, workDate],
        });
    };

    const onRemoveDate = (dateId: number) => {
        setFields({
            ...fields,
            dates: [...fields.dates.filter((workDate) => workDate.id !== dateId)],
        });
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value, name },
        } = event;

        setFields({
            ...fields,
            [name]: value,
        });
    };

    const onSingleSelectChange = (event: SelectChangeEvent) => {
        const {
            target: { value, name },
        } = event;

        setFields({
            ...fields,
            [name]: value,
        });
    };

    const onAddNewAgent = (newAgent: WorkAgent) => {
        let agent = { ...newAgent };
        const existentAgent = work?.agents ? work.agents.find((a) => isEqual(omit(a, ['id']), omit(newAgent, ['id']))) : null;
        if (existentAgent) agent = existentAgent;
        setFields({
            ...fields,
            agents: [...fields.agents, agent],
        });
    };

    const onRemoveAgent = (workAgentId: number) => {
        setFields({
            ...fields,
            agents: [...fields.agents.filter((a) => a.id !== workAgentId)],
        });
    };

    const onSelectedAgentToEdit = (id: number) => {
        setAgentSelectedToEdit(id);
    };

    const onCheckYears = (date: WorkDate) => {
        if (date.year_from && date.year_until) {
            if (date.year_from > date.year_until) {
                enqueueSnackbar(t('work.form.fromSmallerToYear'), { variant: 'error' });
                return false;
            }
        }
        
        const currDate = new Date();
        const threeYearsFromNow = new Date(currDate.setFullYear(currDate.getFullYear() + 3));

        const untilDate = date.year_until && new Date(date.year_until, 0);
        const fromDate = !date.year_from && date.date_value && new Date(date.date_value, 0);

        if (untilDate && untilDate > threeYearsFromNow) {
            enqueueSnackbar(t('work.form.errorMaxYear', { yearType: 'until', maxYear: threeYearsFromNow.getFullYear().toString() }), { variant: 'error' });
            return false;
        }

        if (fromDate && fromDate > threeYearsFromNow) {
            enqueueSnackbar(t('work.form.errorMaxYear', { yearType: 'from', maxYear: threeYearsFromNow.getFullYear().toString() }), { variant: 'error' });
            return false;
        }

        if ((date.year_from && date.year_from < MIN_WORK_YEAR)
        || (date.year_until && date.year_until < MIN_WORK_YEAR)
        || (date.date_value && date.date_value < MIN_WORK_YEAR)
        ) {
            enqueueSnackbar(t('work.form.minimumYearToAdd'), { variant: 'error' });
            return false;
        }
        return true;
    };
    
    const onCloseDialog = (data?: Agent) => {
        if (data) {
            const index = findIndex(fields.agents, (o) => { return o.agent_id === data.id; });
            setFields(update(fields, {
                agents: {
                    [index]: {
                        $merge: {
                            name: data.name,
                            company: data.company,
                            first_name: data.first_name,
                            last_name: data.last_name,
                            extra_name_info: data.extra_name_info,
                            name_inverted: data.name_inverted,
                            alternate_names: data.alternate_names,
                        },
                    },
                },
            }));
        }
        setAgentSelectedToEdit(null);
    };

    const prepare = async () => {
        setIsFetching(true);
        if (params && params.id) {
            const workData = await getWork(Number(params.id));
            if (workData) {
                setFields({
                    preferred_title: workData.preferred_title,
                    sound_production: workData.sound_production || '',
                    notes: workData.notes || '',
                    description: workData.content_description || '',
                    series: workData.series,
                    thirdPartyWorkIds: workData.thirdPartyWorkIds,
                    titles: workData.titles.filter((title) => title.title_type !== WorkTitleType.Preferred),
                    countries: workData.countries || [],
                    dates: workData.dates,
                    agents: workData.agents || [],
                });

                setWork(workData);
                setWorkArchives(workData.archiveWorks || []);
            }
        } else {
            setFields(initialFieldsValues);
            setWork(null);
        }
        setIsFetching(false);
    };

    return (
        <Container maxWidth="md">
            {isFetching && <Loader />}
            { agentSelectedToEdit && <AgentFormDialog agentId={agentSelectedToEdit} onClose={onCloseDialog} />}
            <div className="work-form">
                <div className="work-screen-top">
                    <div className="work-screen-top__img-container">
                        <IconCircleWork />
                    </div>
                    {work ? (
                        <div className="work-screen-top__info">
                            <div className="work-screen-top__info__tags">
                                <Chip label={work.fiaf_work_id} size="small" />
                            </div>
                            <h1>{fields.preferred_title}</h1>
                        </div>
                    ) : (
                        <div className="work-screen-top__info work-screen-top__info--centered">
                            <h1>{fields.preferred_title}</h1>
                        </div>
                    )}
                    <div className="work-screen-top__actions work-screen-top__actions--centered">
                        <Button data-testid="create-work-btn" variant="contained" type="button" onClick={onSubmit}>
                            {work ? t('work.form.saveChanges') : t('work.form.createWork')}
                        </Button>
                    </div>
                </div>
                <div className="work-form__inner">
                    <div className="work-form__inner__section">
                        <h3>{t('work.form.identifiers')}</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <WorkFormIdentifiers
                                    thirdPartyNames={fields.thirdPartyWorkIds}
                                    onAdd={onAddNewThirdPartyName}
                                    onEdited={onThirdPartyNameEdited}
                                    onRemove={onRemoveIdentifier}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className="work-form__inner__section">
                        <h3>{t('work.form.titles')}</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>{t('work.form.preferredTitle')}</InputLabel>
                                    <TextField
                                        fullWidth
                                        inputProps={{ 'data-testid': 'preferred-title-input', maxLength: 200 }}
                                        name={FieldName.PreferredTitle}
                                        value={fields.preferred_title}
                                        onChange={onInputChange}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <WorkFormTitlesTable
                                    titles={fields.titles}
                                    onAdd={onAddNewTitle}
                                    onRemove={onRemoveTitle}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <div className="work-form__inner__section__chips-wrapper">
                                    <InputLabel>{t('work.form.series')}</InputLabel>
                                    <ChipsInput
                                        onAdd={onAddNewSerie}
                                        values={fields.series.map((s) => ({ value: s.title }))}
                                        placeholder={t('work.form.startTyping')}
                                        onRemove={onRemoveSerie}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="work-form__inner__section">
                        <h3>{t('work.form.production')}</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel>{t('work.form.sound')}</InputLabel>
                                    <Select
                                        fullWidth
                                        MenuProps={{ classes: { paper: 'select' } }}
                                        data-testid="work-sound-type"
                                        name={FieldName.SoundProduction}
                                        value={fields[FieldName.SoundProduction]}
                                        onChange={onSingleSelectChange}
                                        displayEmpty
                                    >
                                        <MenuItem dense value="">
                                            <em>{t('general.select')}</em>
                                        </MenuItem>
                                        {soundProductionOptions.map((value) => (
                                            <MenuItem
                                                data-testid="work-sound-type-item"
                                                dense
                                                key={value.name}
                                                value={value.name}
                                            >
                                                {value.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <WorkFormCountriesTable
                                    onAdd={onAddNewCountry}
                                    onRemove={onRemoveCountry}
                                    countries={fields[FieldName.Countries]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <WorkFormDatesTable
                                    dates={fields.dates}
                                    onAdd={onAddNewDate}
                                    onRemove={onRemoveDate}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel>{t('work.form.notes')}</InputLabel>
                                    <TextField
                                        fullWidth
                                        inputProps={{ 'data-testid': 'notes-input' }}
                                        name={FieldName.Notes}
                                        value={fields[FieldName.Notes]}
                                        onChange={onInputChange}
                                        multiline
                                        minRows={2}
                                        maxRows={3}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel>{t('work.form.description')}</InputLabel>
                                    <TextField
                                        fullWidth
                                        inputProps={{ 'data-testid': 'description-input' }}
                                        name={FieldName.Description}
                                        value={fields[FieldName.Description]}
                                        onChange={onInputChange}
                                        multiline
                                        minRows={2}
                                        maxRows={3}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="work-form__inner__section">
                        <h3>{t('work.form.agents')}</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <WorkFormAgentsTable
                                    workAgents={fields.agents}
                                    onAdd={onAddNewAgent}
                                    onRemove={onRemoveAgent}
                                    agentIdSelected={onSelectedAgentToEdit}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    {work && workArchives.length > 0 && (
                        <div className="work-form__inner__section">
                            <h3>{t('work.form.items')}</h3>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <WorkFormItems
                                        archiveWorks={workArchives}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default withTranslationContext(withWorksContext(withControlledVocabulariesContext(withItemsContext(WorkFormScreen))));
