/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { Item } from './items';
import { ArchiveWork } from './archive_works';
import { Agent, AgentAlternateName, AgentType } from './agents';
import { LinksApiResponse, MetaApiResponse } from './api';

export enum WorkTitleType {
    Alternative = 'Alternative',
    Preferred = 'Preferred',
}

export interface WorkTitle {
    id: number;
    title_type: WorkTitleType | string;
    value: string;
}

export interface WorkDate {
    id: number;
    date_type: string;
    date_accuracy: boolean;
    date_value: number | null;
    year_from: number | null;
    year_until: number | null;
    date_string: string;
}

export interface WorkCountry {
    id: number;
    country_ISO_code: string;
    name: string;
    country_probable: boolean;
}

export interface WorkDirectors {
    id: number;
    agent_role: AgentType.Director;
    name: string;
    name_probable: boolean;
}

export interface WorkAgent {
    id: number;
    agent_id: number;
    agent_role: string;
    name: string;
    first_name: string;
    last_name: string;
    extra_name_info: string;
    name_inverted: boolean;
    company: boolean;
    alternate_names: AgentAlternateName[];
}

export interface WorkSeries {
    id: number;
    title: string;
}

export interface WorkThirdPartyIDs {
    fiaf_work_id: number;
    id: number;
    id_value: string;
    third_party_name: string;
}

export interface Work {
    metadata_id: number;
    metadata_associated_to: string;
    fiaf_work_id: number;
    archive_work_id: number | null;
    preferred_title: string;
    sound_production: string | null;
    content_description: string | null;
    notes: string | null;
    titles: WorkTitle[];
    series: WorkSeries[];
    dates: WorkDate[];
    thirdPartyWorkIds: WorkThirdPartyIDs[];
    countries?: Array<WorkCountry> | null;
    agents?: Array<WorkAgent>;
    directors?: Array<WorkDirectors> | null;
    items?: Array<Item>;
    archiveWorks?: Array<ArchiveWork>;
}

export interface WorkApiResponse {
    data: Work[];
    links: LinksApiResponse;
    meta: MetaApiResponse;
}

export enum AccessibleType {
    Accessible = 'accessible',
    NonAccessible = 'non_accessible'
}

export enum WorkIdType {
    FiafWorkId = 'fiaf_work_id',
    LocalWorkId = 'local_work_id',
}

export enum TitleType {
    Preferred = 'Preferred',
}

export interface WorkFormAgentFields {
    agent: Agent | null;
    agent_role: string;
}

export interface WorkFormDateFields {
    id: number;
    date_type: string;
    date_accuracy: boolean;
    year_from: string;
    year_until: string;
}

export interface WorkFormFields {
    preferred_title: string;
    notes: string;
    description: string;
    sound_production: string;
    thirdPartyWorkIds: Array<WorkThirdPartyIDs>;
    titles: Array<WorkTitle>;
    countries: Array<WorkCountry>;
    dates: Array<WorkDate>;
    series: Array<WorkSeries>;
    agents: Array<WorkAgent>;
}

export interface WorkPayload {
    content_description: string;
    sound_production: string;
    notes: string;
    titles: Array<WorkTitle>;
    series: Array<{ id: number | null, title: string }>;
    countries: Array<WorkCountry>;
    dates: Array<WorkDate>;
    agents: Array<WorkAgent>;
    thirdPartyWorkIds: Array<Omit<WorkThirdPartyIDs, 'fiaf_work_id'>>;
}

export interface SingleWorkSuggestion {
    type: string;
    value: string;
}

export interface WorkSuggestionApiResponse {
    titles: Array<SingleWorkSuggestion>;
    series: Array<SingleWorkSuggestion>;
    agents: Array<SingleWorkSuggestion>;
}
